<template>
  <div class="">

    <v-card-title><p class="mb-0 text-h5">Acerca del Conservador</p></v-card-title>

    <v-card-text>
      <v-expansion-panels v-model="panelAbierto">
        <v-expansion-panel
            v-for="(data,i) in items"
            :key="i"
            :id="data.id"
            :class="{'blue lighten-5': panelAbierto === i}"
        >
          <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <div class=" align-center">
                <p class="font-weight-regular" :class="{'mb-2': open, 'mb-0': !open}">{{ data.titulo }}</p>
                <v-fade-transition>


                  <a v-if="open"
                     key="open"
                     :href="'#'+data.id"
                     class="mt-3"
                  >
                    {{ '#' + data.id }}
                  </a>
                </v-fade-transition>
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="mb-0 text-justify" v-html="data.cuerpo"></p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>


  </div>
</template>

<script>
export default {
  name: "InformacionViewAcercaDe",
  data() {
    return {
      panelesAbiertos: [],
      panelAbierto: null,
      items:
          [
            {
              id: "resena-historica",
              titulo: "Reseña Histórica",
              cuerpo: "" +
                  "<p style='text-align: justify'>La sra. Cecira Figari Rojas, cédula de identidad número 3.602.124-1, asumió el cargo de Conservador de Bienes Raíces y Archivero Judicial de Coquimbo con fecha 16 de enero del año 2001. Durante los años 1978-1979 sirvió el cargo de Notario Póblico Suplente y luego en forma interina en la comuna de Illapel. Entre los años 1980 y enero 2001 el cargo de Notario Público, Conservador de Bienes Raíces y Archivero Judicial en las comunas de Vicuña y Paihuano.</p>" +
                  "<p class='mb-0' style='text-align: justify'>Tan pronto asumió sus funciones se buscó un inmueble que acogiera dignamente las dependencia de este Oficio y además, se preocupó de dotarlo de moderna tecnología y acortar los plazos de entrega de los trabajos requeridos.  Se instauró un programa computacional que contiene los datos básicos del propietario y del inmueble, desde el año 1950 a la fecha, lo que permite revisar las respectivas inscripciones con mayor fluidez.</p>"
            },
            {
              id: "sintesis",
              titulo: 'Síntesis de la Organización',
              cuerpo:'<p class="mb-0" style="text-align: justify">Este Oficio está a cargo de la sra. Conservador de Bienes Raíces y Archivero Judicial. Se cuenta con un oficial Primero, abogados asesores y además de funcionarios administrativos. </p>'
            }
          ]

    }
  },
  mounted() {
    this.openTab(this.$route.hash);
  },
  watch: {
    $route(to) {
      this.openTab(to.hash);
    }
  },
  methods: {
    openTab(hash) {
      const _hash = hash.replace("#", "");

      let index = -1;

      switch (_hash) {
        case "quien-es":
          index = 0;
          break;
        case "donde-ejerce":
          index = 1;
          break;
        case "funciones":
          index = 2;
          break;
        default:
          index = -1;
      }

      if (index !== -1) {
        this.panelAbierto = index;

        setTimeout(() => {
          this.$vuetify.goTo(hash, {
            easing: "easeOutCubic",
            offset: 150
          });
        }, 500);

      }
    }
  }
}
</script>

<style scoped>

</style>
